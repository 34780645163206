import { useTranslation } from 'react-i18next'
import { BiCopyright } from 'react-icons/bi'
import {
    BsFacebook,
    BsFillPersonBadgeFill,
    BsInstagram
} from 'react-icons/bs'
import LOGO from '../../assets/logo_footer.png'
import './styles.css'

const Footer = () => {
    const { t } = useTranslation();

    const goToLink = (link: string) => {
        window.open(link, '_blank');
    }

    return (
        <section id='footer'>
            <div className='footer__container'>
                <div className='footer__socials'>
                    <div className='footer__socials__logo'>
                        <img src={LOGO} alt="logo footer" />
                    </div>
                    <div className='footer__socials__icons'>
                        <h3>{t('footer.follow_us')}</h3>
                        <div onClick={() => goToLink('https://www.facebook.com/profile.php?id=100070953780843')} className='footer__socials__icon footer_icon'><BsFacebook /></div>
                        <div onClick={() => goToLink('https://www.instagram.com/dr.grillz.bcn')} className='footer__socials__icon footer_icon'><BsInstagram /></div>
                    </div>
                </div>
                <div className='footer__informations'>
                    <h3>{t('footer.information')}</h3>
                    <div className='footer__informations__contact'>
                        <div className='footer__informations__icon footer_icon'><BsFillPersonBadgeFill /></div>
                        <div className='footer__informations__content'>
                            <p>drgrillzbcn@gmail.com</p>
                            <p>{t('footer.days')}</p>
                        </div>
                    </div>
                    {/* <div className='footer__informations__location'>
                        <div className='footer__informations__icon footer_icon'><BsFillPinMapFill /></div>
                        <div className='footer__informations__content'>
                            <p>Calle comte borrell 118 bajos</p>
                            <p>08015 Barcelona</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='footer__copyright'>
                <BiCopyright />2022 Dr Grillz BCN | {t('footer.made_by')} <a href="https://jeromegallego.fr/" target="_blank" rel="noreferrer">Jérôme GALLEGO</a>
            </div>
        </section>
    )
}

export default Footer
