import AwesomeSlider from 'react-awesome-slider';
// @ts-ignore
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';


// import SLIDER1 from '../../assets/slider1.jpeg';
import SLIDER2 from '../../assets/slider2.jpeg';
import SLIDER3 from '../../assets/slider3.jpeg';

import { useTranslation } from 'react-i18next';
import './styles.css';

// https://www.npmjs.com/package/react-awesome-slider
// https://caferati.me/demo/react-awesome-slider/images

const Slider = () => {
	const { t } = useTranslation('slider');

	return (
		<section id='slider'>
			<div className='container slider__container'>
				<AwesomeSlider cssModule={AwesomeSliderStyles} className={`awesome-slider`}>
					{/* <div data-src={SLIDER1} data-alt="DRGRILLZBCN - Slider n°1" /> */}
					<div data-src={SLIDER2} data-alt="DRGRILLZBCN - Slider n°2" />
					<div data-src={SLIDER3} data-alt="DRGRILLZBCN - Slider n°3" />
				</AwesomeSlider>
				<div className='slider__informations'>
					<p>
						{t('slider:description_1')}
					</p>
					<p>
						{t('slider:description_2')}
					</p>
				</div>
			</div>
		</section>
	)
}

export default Slider